import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl';

import Button from '../../components/Button/Button';
import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Alert from '../../components/Alert/Alert';

const BuilderRenewalChecklistPage = () => {
	const intl = useIntl();
	return (
		<Layout
			view='builder'
			subheader={<FormattedMessage id='nav.builderDashB' />}
			header={
				<span className='bold'>
					<FormattedMessage id='renewAppChecklist.pageHeader' />
				</span>
			}
		>
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({
					id: 'renewAppChecklist.pageHeader'
				})}
			/>
			<Container>
				<Row className=''>
					<Col>
						<ol className='largeNums'>
							<li>
								<div>
									<FormattedMessage id='renewAppChecklist.list01' />
								</div>
							</li>
							<li>
								<div>
									<FormattedMessage id='renewAppChecklist.list02a' />{' '}
									{/* <a
                    href='https://builderportal.hcraontario.ca/'
                    aria-label={intl.formatMessage({
                      id: 'renewAppChecklist.list02b'
                    })}
                  > */}
									<FormattedMessage id='renewAppChecklist.list02b' />
									{/* </a> */}
									.
									<br />
									<br />
									<Button
										linkExternal
										linkPath='https://builderportal.hcraontario.ca'
										text={intl.formatMessage({
											id: 'becomeBuilder5.contentCiii'
										})}
									/>
								</div>
							</li>
							<li>
								<FormattedMessage id='renewAppChecklist.list03a' />
								<br />
								<FormattedMessage id='renewAppChecklist.list03b' />
							</li>
							<li>
								<div>
									<FormattedMessage id='renewAppChecklist.list04' />
									<ul>
										<li>
											<FormattedMessage id='renewAppChecklist.list04i' />
										</li>
										<li>
											<FormattedMessage id='renewAppChecklist.list04ii' />
										</li>
										<li>
											<FormattedMessage id='renewAppChecklist.list04iii' />
										</li>
										<li>
											<FormattedMessage id='renewAppChecklist.list04iv' />
										</li>
										<li>
											<FormattedMessage id='renewAppChecklist.list04v' />
										</li>
									</ul>
								</div>
							</li>
							<li className='centered'>
								<FormattedMessage id='renewAppChecklist.list05' />
							</li>
							<li className='centered'>
								<FormattedMessage id='renewAppChecklist.list06' />
							</li>
						</ol>

						<Alert>
							<FormattedMessage id='renewAppChecklist.contentA' />{' '}
							<a
								href={`../../../${intl.formatMessage({
									id: 'linkNames.renewInstructionLink'
								})}`}
								aria-label={intl.formatMessage({
									id: 'renewAppChecklist.contentB'
								})}
							>
								<FormattedMessage id='renewAppChecklist.contentB' />
							</a>{' '}
							<FormattedMessage id='renewAppChecklist.contentC' />
						</Alert>

						<Alert>
							<FormattedMessage id='renewAppChecklist.contentD' />{' '}
							<Link to='/builder-vendor/faq/'>
								<FormattedMessage id='renewAppChecklist.contentE' />
							</Link>{' '}
							<FormattedMessage id='renewAppChecklist.contentF' />
						</Alert>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default BuilderRenewalChecklistPage;
